import React, { useCallback, useState } from "react"
import Img from "gatsby-image"
import classNames from "classnames"

import { useCurrentTheme } from "../../../utils/themeChanger"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import Section from "../../../ui-kit/Section/Section"
import Heading from "../../../ui-kit/Heading/Heading"
import StyledCard from "../../../ui-kit/StyledCard/StyledCard"
import Button from "../../../ui-kit/Button/Button"
import Input from "../../../ui-kit/Input/Input"

import InThePressSkeleton from "../InThePressSkeleton/InThePressSkeleton"

// Styles
import * as styles from "./InThePress.module.sass"

// Icons
import ReadMore from "../../../icons/light/InThePress/arrow.svg"
import Placeholder from "../../../icons/light/placeholder.svg"
import SearchIconLight from "../../../icons/light/Search.svg"
import SearchIconDark from "../../../icons/dark/Search.svg"
import LazyHydrate from "react-lazy-hydration"

const BlockPress = ({
  header,
  button,
  data,
  noCategory,
  lang = "en",
  isBig = false,
  viewMore,
  withButton = false,
  setSearchValue,
  loading,
}) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  const [searchString, setSearchString] = useState("")

  const handleSearchChange = useCallback(e => {
    setSearchString(e.target.value)
  }, [])

  const handleSearchSubmit = useCallback(
    e => {
      e.preventDefault()

      setSearchValue(searchString)
    },
    [searchString, setSearchValue]
  )

  return (
    <Section className={classNames({ [styles.section]: isBig })}>
      <div className={styles.content}>
        <Container>
          <Row>
            <Heading theme="left">
              <div className={styles.headerRow}>
                <h2>{header}</h2>

                {isBig && (
                  <form
                    onSubmit={handleSearchSubmit}
                    className={styles.searchRow}
                  >
                    <Input
                      value={searchString}
                      className={styles.searchInput}
                      label={"Search"}
                      placeholder="Search"
                      onChange={handleSearchChange}
                    />
                    <Button
                      type="submit"
                      className={styles.searchButton}
                      theme={"ternary"}
                      disabled={loading}
                    >
                      <img
                        src={isLightTheme ? SearchIconLight : SearchIconDark}
                        alt="Search"
                      />
                    </Button>
                  </form>
                )}
              </div>
            </Heading>

            {loading ? (
              <InThePressSkeleton isBig />
            ) : (
              <>
                {data?.length === 0 && (
                  <h3>No items matching your search were found.</h3>
                )}
                <div
                  className={classNames(styles.cards, {
                    [styles.cardsBig]: isBig,
                  })}
                >
                  {data.map(({ node: article }) => (
                    <div key={article.id} className={styles.card}>
                      <StyledCard link={`/blog/${article.slug}`}>
                        <div className={styles.inner} title={article.title}>
                          <LazyHydrate whenIdle>
                            <div className={styles.image}>
                              {article.image ? (
                                <div className={styles.icon}>
                                  <Img
                                    className={styles.iconImage}
                                    fluid={
                                      article.image.localFile.childImageSharp
                                        .fluid
                                    }
                                    alt={article.title || article.title_zh}
                                  />
                                </div>
                              ) : (
                                <div className={styles.placeholder}>
                                  <StyledCard>
                                    <div className={styles.placeholderInner}>
                                      <img src={Placeholder} alt="" />
                                    </div>
                                  </StyledCard>
                                </div>
                              )}
                            </div>
                          </LazyHydrate>
                          <LazyHydrate ssrOnly>
                            <div className={styles.contentBlock}>
                              <div className={styles.nameAndData}>
                                <p
                                  className={classNames(styles.category, {
                                    [styles.noCategory]: !(
                                      article.category || article.category_zh
                                    ),
                                  })}
                                >
                                  {article.category || article.category_zh
                                    ? article.category || article.category_zh
                                    : noCategory}
                                </p>
                                <p className={styles.date}>
                                  {article.created_on}
                                </p>
                              </div>
                              <h3 title={article.title || article.title_zh}>
                                {article.title || article.title_zh}
                              </h3>
                              <p className={styles.describe}>
                                {article.description || article.description_zh}
                              </p>
                              <p className={styles.link}>
                                {button} <img src={ReadMore} alt="Read More" />
                              </p>
                            </div>
                          </LazyHydrate>
                        </div>
                      </StyledCard>
                    </div>
                  ))}
                </div>
              </>
            )}
            {withButton && (
              <LazyHydrate ssrOnly>
                <div className={styles.button}>
                  <Button link={`/blog`}>{viewMore}</Button>
                </div>
              </LazyHydrate>
            )}
          </Row>
        </Container>
      </div>
    </Section>
  )
}

export default BlockPress
